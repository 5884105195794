import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Type Brigade is Vancouver’s typography and type design meetup. We’re excited to announced that Type Brigade №24 is on February 18th.`}</p>
    <ul>
      <li parentName="ul">{`Doors: 6:30PM`}</li>
      <li parentName="ul">{`Talks begin: 7:00PM`}</li>
      <li parentName="ul">{`No admittance during talks`}</li>
    </ul>
    <p>{`Every other month, Type Brigade hosts one typography and one type design talk. For our first event of the new year, we’ll hear from artists Emma Metcalfe Hurst & Ingrid Olauson, and type designer Ross Milne.`}</p>
    <p><img parentName="p" {...{
        "src": "http://photos1.meetupstatic.com/photos/event/e/4/2/a/600_433558410.jpeg",
        "alt": null
      }}></img></p>
    <h2>{`Emma Metcalfe Hurst & Ingrid Olauson — Cult Typography: The Harlequin Romance as Critical Aesthetic`}</h2>
    <p>{`Looking back historically at type as a form of branding and voice, the cult typography of the Harlequin Romance novel will be revived in conversation. Topics such as myth, desire, fantasy and projection will serve as a basis to reflect on the collaborative and research based project Ingrid and Emma undertook for their photo and text based Harlequin Novel project `}<cite>{`Paradise Passes Through Here`}</cite>{`. By mimicking the poetic affectiveness of the pulp aesthetic, a connection will be drawn in relation to the politics of Feminist discourse and it's recognition in conceptual art.`}</p>
    <p><em parentName="p">{`Emma Metcalfe Hurst & Ingrid Olauson are artists in Vancouver.`}</em></p>
    <h2>{`Ross Milne — When is a typeface done? The process of development of Charlie & Echo`}</h2>
    <p>{`For now, Ross Milne’s new typefaces `}<a parentName="p" {...{
        "href": "https://www.typotheque.com/blog/echo_and_charlie"
      }}>{`Charlie & Echo`}</a>{` are done. Recently release through the Typotheque, with support for over 140 languages across Latin, Cyrillic and Greek scripts, eleven weights with corresponding italics, numerous figure styles, expanded icons, arrows and too many currency symbols to count, it’s fair to say that Charlie has grown bigger than ever envisioned. He’ll share some of the process in getting to that point at Type Brigade №24.`}</p>
    <p><img parentName="p" {...{
        "src": "http://photos2.meetupstatic.com/photos/event/e/2/2/c/600_433557900.jpeg",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`Ross Milne is a type designer and founding member of the design studio `}<a parentName="em" {...{
          "href": "http://workingformat.com"
        }}>{`Working Format`}</a>{`. He completed his postgraduate studies at the Type & Media program at the KABK in The Hauge, Netherlands, and has gone onto regularly work with Typotheque there, and Commercial Type in New York. He currently teaches type design at Emily Carr University.`}</em></p>
    <h2>{`Custom Numerals`}</h2>
    <p>{`We signify each Type Brigade with a new set of custom-lettered numerals. Last time, `}<a parentName="p" {...{
        "href": "https://twitter.com/rileycran"
      }}>{`Riley Cran`}</a>{` `}{`designed our fantastic №23. Leading up to the event, we’ll reveal the new №24 by our`}{` `}{`resident letterer `}<a parentName="p" {...{
        "href": "http://instagram.com/andreahusky"
      }}>{`Andrea Husky`}</a>{`.`}{` `}</p>
    <p><img parentName="p" {...{
        "src": "http://photos3.meetupstatic.com/photos/event/7/4/6/a/600_387269802.jpeg",
        "alt": null
      }}></img></p>
    <h2>{`Sponsors`}</h2>
    <p>{`Type Brigade is sponsored by `}<a parentName="p" {...{
        "href": "http://mobify.com"
      }}>{`Mobify`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.portpaperco.com/"
      }}>{`Port Paper Co,`}</a>{` and `}<a parentName="p" {...{
        "href": "http://chloi.io"
      }}>{`Chloi Inc.`}</a></p>
    <p>{`Chloi helps make Type Brigade possible by sponsoring overhead for these events. If your team needs expertise on building mobile and web apps efficiently, `}<a parentName="p" {...{
        "href": "http://chloi.io/contact"
      }}>{`contact us`}</a>{`.`}</p>
    <p>{`Mobify kindly lets us use their head quarters for Type Brigade space. They’re always welcoming, and also hiring! Learn more about `}<a parentName="p" {...{
        "href": "http://mobify.com/careers"
      }}>{`careers at Mobify`}</a>{`.`}</p>
    <p>{`Our friends at Port Paper Co. are sponsoring this event’s print. They are a impressive, custom letterpress studio and producer of fine paper goods. `}<a parentName="p" {...{
        "href": "http://www.portpaperco.com/"
      }}>{`Print like it's 1450 all over again!`}</a></p>
    <p>{`If you or your company is interested in sponsoring drinks at this even, or being part of a future event, send me an email at `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`kenneth@typebrigade.com`}</a>{`.`}</p>
    <h3>{`Additional information`}</h3>
    <p>{`A photographer and filming team from or commissioned by Type Brigade will be taking photos and may also film the meetup. The media may be published on the Type Brigade websites or circulated to promote future meetups.`}</p>
    <p>{`Soda and beer are both available at the event. Please bring proper ID if you’d like to have any of the latter.`}</p>
    <p>{`After the talks, we’ll walk to a nearby venue for food and drinks, and to continue our conversations. We’d love for you to join us!`}</p>
    <p>{`If you have questions about any of these details, please contact Kenneth at kenneth@typebrigade.com.`}</p>
    <h3>{`Code of Conduct`}</h3>
    <p>{`Type Brigade strives to create a welcoming environment for everyone, which we commit to through our attendee, organiser, speaker, and sponsor `}<a parentName="p" {...{
        "href": "http://typebrigade.com/code-of-conduct"
      }}>{`Code of Conduct`}</a>{`.`}</p>
    <h3>{`Follow us`}</h3>
    <p>{`Make sure you follow `}<a parentName="p" {...{
        "href": "http://twitter.com/typebrigade"
      }}>{`@typebrigade on Twitter`}</a>{`, and mention us if you have any questions.`}</p>
    <p>{`See you at the event!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      